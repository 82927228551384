import { useEffect } from 'react';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  WorkboxLifecycleEvent,
  WorkboxMessageEvent,
} from 'workbox-window/utils/WorkboxEvent';

export const PWAPrompt = () => {
  const intl = useIntl();

  // register service worker
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined &&
      process.env.NODE_ENV === 'production'
    ) {
      const wb = window.workbox;
      const handleInstalled = (event: WorkboxLifecycleEvent) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Event ${event.type} is triggered.`);
          console.log(event);
        }
      };
      const handleControlling = (event: WorkboxLifecycleEvent) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Event ${event.type} is triggered.`);
          console.log(event);
        }
      };
      const handleActivated = (event: WorkboxLifecycleEvent) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Event ${event.type} is triggered.`);
          console.log(event);
        }
      };
      const handleMessage = (event: WorkboxMessageEvent) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Event ${event.type} is triggered.`);
          console.log(event);
        }
      };

      // add event listeners to handle any of PWA lifecycle event
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
      wb.addEventListener('installed', handleInstalled);
      wb.addEventListener('controlling', handleControlling);
      wb.addEventListener('activated', handleActivated);

      // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
      // NOTE: MUST set skipWaiting to false in next.config.js pwa object
      // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
      const promptNewVersionAvailable = () => {
        // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        window.alert(
          intl.formatMessage({ id: 'pwaNewVersionDialogDescription' }),
        );

        wb.addEventListener('controlling', () => {
          window.location.reload();
        });

        // Send a message to the waiting service worker, instructing it to activate.
        wb.messageSkipWaiting();
      };

      wb.addEventListener('waiting', promptNewVersionAvailable);

      // ISSUE - this is not working as expected, why?
      // I could only make message event listenser work when I manually add this listenser into sw.js file
      wb.addEventListener('message', handleMessage);

      /*
      wb.addEventListener('redundant', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      wb.addEventListener('externalinstalled', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      wb.addEventListener('externalactivated', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      */

      // never forget to call register as auto register is turned off in next.config.js
      wb.register();

      return () => {
        wb.removeEventListener('waiting', promptNewVersionAvailable);
        wb.removeEventListener('installed', handleInstalled);
        wb.removeEventListener('controlling', handleControlling);
        wb.removeEventListener('activated', handleActivated);
        wb.removeEventListener('message', handleMessage);
      };
    }
  }, [intl]);

  return null;
};
