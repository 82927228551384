import { cache } from 'emotion';
import { CacheProvider } from '@emotion/core';
// import { ApolloProvider } from '@apollo/client';
import { ResponsiveDesignSystem, GlobalStyles } from '@byteclaw/visage';
import { AppProps } from 'next/app';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
// import { ErrorHandler } from '@apollo/client/link/error';
import Head from 'next/head';
/* import {
  extractGraphQLErrorCode,
  shouldHandleErrorOnAppLevel,
  USER_NOT_AUTHENTICATED_ERROR,
} from '../errorCodes'; */
import { WebSite } from 'schema-dts';
import { PWAPrompt } from '../components/PWAPrompt';
import { AnalyticsTrackCookieEvents } from '../components/AnalyticsTrackCookieEvents';
// import { useApollo } from '../apollo';
import { styleGenerator, theme } from '../theme';
import { JsonLd } from '../jsonld/JsonLd';
import { siteMetadata } from '../constants';

const messages = {};

const defaultRenderLayout = (children: ReactNode) => {
  return <>{children}</>;
};

export default function App({ Component, pageProps }: AppProps) {
  /* const handleApolloError = useRef<ErrorHandler>(
    ({ networkError, graphQLErrors }) => {
      // make sure you don't handle the same errors in query / mutation handlers
      // otherwise you will end up with duplicit notifications

      if (networkError) {
        // @TODO show notification about error
        // notifications.publish(
        //   'error',
        //   <FormattedMessage id="appNotificationError_NETWORKERROR" />,
        // );
      }

      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (shouldHandleErrorOnAppLevel(err)) {
            const errCode = extractGraphQLErrorCode(err);

            // eslint-disable-next-line default-case
            switch (errCode) {
              case USER_NOT_AUTHENTICATED_ERROR: {
                // @TODO show notification about user not logged in
                // notifications.publish(
                //   'error',
                //   <FormattedMessage id={`appNotificationError_${errCode}`} />,
                // );

                // @TODO redirect to login
                return;
              }
            }
          }
        }
      }
    },
  );
  const apolloClient = useApollo({
    initialState: pageProps.initialApolloState,
    onError(response) {
      // so we can use always fresh values from router, etc
      return handleApolloError.current(response);
    },
  }); */
  const renderLayout = (Component as any).renderLayout || defaultRenderLayout;

  return (
    // make sure global styles imported by visage have nonce also
    <CacheProvider value={cache}>
      <ResponsiveDesignSystem styleGenerator={styleGenerator} theme={theme}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
          />
        </Head>
        <GlobalStyles styles={{ 'html *': { fontFamily: 'body' } }} />
        {/* <ApolloProvider client={apolloClient}> */}
        <IntlProvider defaultLocale="sk-SK" locale="sk-SK" messages={messages}>
          <JsonLd<WebSite>
            item={{
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: siteMetadata.title,
              url: siteMetadata.siteUrl,
              sameAs: [siteMetadata.facebookUrl, siteMetadata.instagramUrl],
            }}
          />
          {renderLayout(<Component {...pageProps} />)}
          <PWAPrompt />
          <AnalyticsTrackCookieEvents />
        </IntlProvider>
        {/* </ApolloProvider> */}
      </ResponsiveDesignSystem>
    </CacheProvider>
  );
}
