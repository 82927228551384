import * as Sentry from '@sentry/nextjs';
import cuid from 'cuid';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

const EKOZURNAL_TRANSACTION_ID = cuid();

if (typeof window !== 'undefined') {
  window.EKOZURNAL_TRANSACTION_ID = EKOZURNAL_TRANSACTION_ID;
}

Sentry.init({
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.VERCEL_ENV,
  // DSN is taken from SENTRY_DSN env variable
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error', 'warn'],
    }),
  ],
  ...(process.env.NODE_ENV === 'production'
    ? {
        tracesSampleRate: 1.0,
      }
    : {}),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

Sentry.configureScope((scope) => {
  scope.setTag('runtime', 'browser');
  scope.setTransactionName(EKOZURNAL_TRANSACTION_ID);
});