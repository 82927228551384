export const ANALYTICS_COOKIE_NAME = '__a_eko';

export const siteMetadata = {
  title: `Ekožurnál.sk`,
  description: `Webový portál, kde nájdete aktuálne články o ekológii zo sveta aj zo Slovenska. Prečítajte si, prečo je ekológia dôležitá a ako môžete prispieť k zdraviu našej planéty.`,
  author: `Ekožurnál.sk`,
  email: 'info@ekozurnal.sk',
  homepageTitle: 'Portál pre udržateľný život',
  siteUrl: 'https://ekozurnal.sk',
  facebookAppId: '858197624666921',
  facebookUrl: 'https://www.facebook.com/ekozurnal.sk',
  instagramUrl: 'https://www.instagram.com/ekozurnal.sk',
};
