import {
  defaultBreakpoints,
  defaultTheme,
  createResponsiveEmotionStyleGenerator,
  ColorPalette,
} from '@byteclaw/visage';
import { createNPointModularScaleTheme } from '@byteclaw/visage-themes';

export const styleGenerator =
  createResponsiveEmotionStyleGenerator(defaultBreakpoints);

// https://coolors.co/264653-2a9d8f-e9c46a-f4a261-e76f51
export const theme = createNPointModularScaleTheme({
  baseGridSize: 8,
  baseFontSize: [18, 20],
  baseLineHeightRatio: 1.65,
  fontScaleRatio: 1.2,
  borderRadius: {
    controlBorderRadius: 0,
    keyboardBorderRadius: 0,
  },
  colors: {
    ...(defaultTheme.theme.colors as ColorPalette),
    shadesText: 'rgba(0, 0, 0, 0.8)',
    primary: '#2a9d8f',
    primaryText: '#fff',
    footerBackground: '#264653',
    footerBackgroundText: '#fff',
    paginatorButtonBackground: '#e76f51',
    paginatorButtonText: '#fff',
    footer: '#F4A261',
  },
  fontFamily: {
    heading: "'Alegreya Sans', sans-serif",
    body: "'Alegreya', serif",
  },
  faces: {},
});

export const globalStyles: { [selector: string]: VisageStyleSheet } = {
  '.twitter-tweet': {
    mx: 'auto',
  },
};
