/* eslint-disable camelcase */

import { useCallback } from 'react';

export type AnalyticsEvent = {};

type EventTrackerFunc = (...events: AnalyticsEvent[]) => void;

export function useAnalyticsEventTracker(): EventTrackerFunc {
  return useCallback((...events) => {
    if (typeof window === 'undefined') {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    for (const ev of events) {
      window.dataLayer.push(ev);
    }
  }, []);
}