import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import cookie from 'js-cookie';
import {
  useAnalyticsEventTracker,
  AnalyticsEvent,
} from '../hooks/useAnalyticsEventTracker';
import { ANALYTICS_COOKIE_NAME } from '../constants';

/**
 * This component tracks events assigned to cookie from API redirects
 *
 * For example google / facebook api handlers or invite endpoints
 *
 * This only works on initial mount
 */
export const AnalyticsTrackCookieEvents = () => {
  const trackEvent = useAnalyticsEventTracker();

  useEffect(() => {
    try {
      // get cookie value
      const events: AnalyticsEvent[] = JSON.parse(
        cookie.get(ANALYTICS_COOKIE_NAME) || '[]',
      );

      // remove cookie
      cookie.remove(ANALYTICS_COOKIE_NAME);

      // map all events and track them
      trackEvent(
        ...events
          .map((event): AnalyticsEvent | undefined => {
            // validate event
            if (
              !(typeof event === 'object' && event !== null && 'event' in event)
            ) {
              return;
            }

            return event;
          })
          .filter<AnalyticsEvent>((v): v is AnalyticsEvent => !!v),
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [trackEvent]);

  return null;
};
