import React from 'react';
import { WithContext, Thing } from 'schema-dts';
import Head from 'next/head';

export function JsonLd<Schema extends Thing>({
  item,
}: {
  item: WithContext<Schema>;
}) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(item) }}
      />
    </Head>
  );
}
